<template>
  <div class="questionnaire_print">
    <!-- 问卷标题 -->
    <div class="questionnaire_print_title">忧郁调查问卷</div>
    <!-- 问卷题目 -->
    <div class="questionnaire_print_main" v-for="(v,k,i) in listData" :key="i">
      <div class="type">
        {{i |filterNum}}、 {{k |filterType}}。 （共
        <span class="type_item">{{allData.num[k]}}</span>
        题，共
        <span class="type_item">{{allData.type[k]}}</span>分，每题
        <span class="type_item">{{ allData.type[k]/allData.num[k]}}</span>分）
      </div>
      <!-- 单选题 -->
      <div v-if="k==1">
        <div class="subject" v-for="(item,key) in v" :key="key">
          <div class="subject_title">{{key+1}}、 {{item.title}}</div>
          <div class="parameter">
            <span
              v-for="(item2,key) in item.parameter.split('\n')"
              :key="key"
              class="parameter_item"
            >{{item2}}</span>
          </div>
          <p>答：（__________）</p>
        </div>
      </div>
      <!-- 多选题 -->
      <div v-if="k==2">
        <div class="subject" v-for="(item,key) in v" :key="key">
          <div class="subject_title">{{key+1}}、 {{item.title}}</div>
          <div class="parameter">
            <span
              v-for="(item2,key) in item.parameter.split('\n')"
              :key="key"
              class="parameter_item"
            >{{item2}}</span>
          </div>
          <p>答：（______________）</p>
        </div>
      </div>
      <!-- 判断题 -->
      <div v-if="k==3">
        <div class="subject" v-for="(item,key) in v" :key="key">
          <div class="subject_title">{{key+1}}、 {{item.title}}</div>
          <div class="parameter">
            <p>
              <span class="parameter_item">
                <i class="fa fa-square-o" aria-hidden="true"></i>&nbsp;&nbsp;对
              </span>
              <span class="parameter_item">
                <i class="fa fa-square-o" aria-hidden="true"></i>&nbsp;&nbsp;错
              </span>
            </p>
          </div>
        </div>
      </div>
      <!-- 填空题 -->
      <div v-if="k==4">
        <div class="subject" v-for="(item,key) in v" :key="key">
          <div class="subject_title">{{key+1}}、 {{item.title |filteBlank}}</div>
        </div>
      </div>
      <!-- 排序题 -->
      <div v-if="k==5">
        <div class="subject" v-for="(item,key) in v" :key="key">
          <div class="subject_title">{{key+1}}、 {{item.title}}</div>
          <div class="parameter">
            <span
              v-for="(item2,key) in item.parameter.split('\n')"
              :key="key"
              class="parameter_item"
            >{{item2}}</span>
          </div>
          <p>答：（___________________________）</p>
        </div>
      </div>
      <!-- 计算题 -->
      <div v-if="k==6">
        <div class="subject" v-for="(item,key) in v" :key="key">
          <div class="subject_title">{{key+1}}、 {{item.title}}</div>
          <div class="parameter">
            <p>答：（_________________）</p>
          </div>
        </div>
      </div>
      <!-- 解答题、问答题、作文题 -->
      <div v-if="k==7 || k==8 ||k==9">
        <div class="subject" v-for="(item,key) in v" :key="key">
          <div class="subject_title">{{key+1}}、 {{item.title}}</div>
          <div class="parameter">
            <p>答：_____________________________________________________________________________</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 <script>
import { setQsubject } from "@/api/questionnaire.js";
export default {
  data() {
    return {
      listData: {}
    };
  },
  created() {
    let id = this.$route.query.id;
    let data = {
      id
    };
    // 发送 渲染 请求
    setQsubject(data).then(res => {
      this.allData = res.data;
      // 数据分类处理
      let obj = {};
      res.data.questionnaire.forEach((v, k) => {
        let key = v.type;
        if (obj.hasOwnProperty(key)) {
          obj[key].push(v);
        } else {
          obj[key] = [];
          obj[key].push(v);
        }
      });

      this.listData = obj;
    });
  },
  methods: {},
  filters: {
    filterType(val) {
      let arr = [
        "单选题",
        "多选题",
        "判断题",
        "填空题",
        "排序题",
        "计算题",
        "解答题",
        "问答题",
        "作文题"
      ];
      return arr[val - 1];
    },
    filterNum(val) {
      let arr = [
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
        "十",
        "十一"
      ];
      return arr[val];
    },
    filteBlank(val) {
      return val.replace(/\(\)/g, "(__________)");
    }
  }
};
</script>
 <style lang="less">
.questionnaire_print {
  width: 800px;
  margin: 0 auto;
  padding: 10px;
  //   background: rgb(221, 220, 220);
  box-sizing: border-box;
  .questionnaire_print_title {
    font-weight: 700;
    font-size: 25px;
    text-align: center;
    margin-bottom: 20px;
  }
  .questionnaire_print_main {
    .type {
      padding: 5px;
      background: rgb(217, 242, 247);
      border-radius: 10px;
      margin: 5px 0;
      color: rgb(16, 42, 121);
      font-size: 18px;
      .type_item {
        color: red;
      }
    }
    .subject {
      margin: 20px 0;
      .subject_title {
        font-weight: 700;
      }
      .parameter {
        margin: 10px 0;
        .parameter_item {
          margin-right: 30px;
        }
      }
    }
  }
}
</style>